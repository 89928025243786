import axios from 'axios';

export const useFetchPosts = async () => {
  try {
    const response = await axios.get(
      'http://localhost:8080/wp-json/wp/v2/posts'
    );
    return response.data;
  } catch (error) {
    console.error('Failed to fetch posts', error);
    return [];
  }
};
