<template lang="pug">
.page-container
  Header(:header="data.header")
  main
      NavBar(:nav1="data.nav1" :nav2="data.nav2" :logo="data.logo")
      .main-container#mainContent 
          div.breadcrunb-container
              Breadcrumb(:breadcrumbs="data.breadcrumb")
          MainCompanyContent(:data="data.content")
          Tabulator
      Aside(:aside="data.aside")
  Footer(:footer="data.footer")
</template>

<script setup lang="ts">
import { onMounted } from 'vue';

const props = defineProps({
  data: Object,
});

function adjustLayout() {
  const navlink = document.getElementById("navlink");
  const aside = document.getElementById("aside");
  const breadcrumb = document.getElementById("breadcrumb");
  // const mainContent = document.getElementById("mainContent");
  const mainWrapper = document.getElementById("main-wrapper");

  const isNavEmpty = !props.data?.nav1.length && !props.data?.nav2.length;
  const isAsideEmpty = !aside?.innerHTML.trim();

  if (isNavEmpty) {
    navlink?.classList.add("hidden");
    breadcrumb?.classList.remove("breadcrumb-below-nav");
    mainWrapper?.classList.remove('max-height-adjusted')
  } else {
    navlink?.classList.remove("hidden");
    breadcrumb?.classList.add("breadcrumb-below-nav");
    mainWrapper?.classList.add('height-adjusted')
  }

  if (isAsideEmpty) {
    aside?.classList.add("hidden");
  } else {
    aside?.classList.remove("hidden");
  }

  // if (isNavEmpty && isAsideEmpty) {
  //   mainContent?.classList.add("full-width");
  // } else {
  //   mainContent?.classList.remove("full-width");
  // }
}

onMounted(() => {
  adjustLayout();
});
</script>

<style scoped>
 @media (max-width: 600px) {
    .hidden {
    display: none;
    }

    .full-width {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    }

    /* .breadcrumb-below-nav {
    margin-top: 30px; 
    font-size: 0.9em;
    padding-left: 5px;
    } */

    .height-adjusted {
      max-height: calc(100vh - 130px);
      height: auto;
    }
}
</style>
