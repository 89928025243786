<script setup lang="ts">
import { ref, reactive, onMounted } from 'vue';
import { TabulatorFull as Tabulator } from 'tabulator-tables';
import 'tabulator-tables/dist/css/tabulator.min.css';

function readStaticTableData() {
  const staticTable = document.querySelector('#static-table tbody');
  if (!staticTable) return [];

  const rows = staticTable.querySelectorAll('tr');
  return Array.from(rows).map(row => {
    const cells = row.querySelectorAll('td');
    const originalName = cells[0].textContent || '';
    const url = cells[1].textContent.trim();
    const name = url ? `<a href="${url}">${originalName}</a>` : originalName;

    return {
      OriginalName: originalName,
      Name: name,
      Phone: cells[2].textContent || '',
      Street: cells[3].textContent || '',
      Postal: cells[4].textContent || '',
    };
  });
}

const isDesktopWide = ref(false);
const isMobileWide = ref(false);

const sortByName = (
  a: any,
  b: any,
  aRow: { getData: () => { (): any; new (): any; OriginalName: any } },
  bRow: { getData: () => { (): any; new (): any; OriginalName: any } },
  column: any,
  dir: any,
  sorterParams: any
) => {
  let aName = aRow.getData().OriginalName;
  let bName = bRow.getData().OriginalName;
  return aName.localeCompare(bName);
};
const columns = [
  {
    title: 'Name',
    field: 'Name',
    sorter: sortByName,
    headerFilter: 'input',
    formatter: 'html',
    responsive: 0,
    resizable: false,
  },
  {
    title: 'Name',
    field: 'Combined',
    sorter: sortByName,
    headerFilter: 'input',
    formatter: nameAndStreetFormatter,
    visible: false,
    resizable: false,
  },
  {
    title: '',
    field: 'Phone',
    formatter: phoneIconFormatter,
    headerSort: true,
    width: 10,
    resizable: false,
  },
  {
    title: 'Street',
    field: 'Street',
    sorter: 'string',
    headerFilter: 'input',
    resizable: false,
  },
  {
    title: 'Postal',
    field: 'Postal',
    sorter: 'string',
    headerFilter: 'input',
    width: 100,
    resizable: false,
  },
];
function nameAndStreetFormatter(cell: {
  getRow: () => { (): any; new (): any; getData: { (): any; new (): any } };
}) {
  const rowData = cell.getRow().getData();
  const name = rowData.Name || '';
  const street = rowData.Street || '';

  return `<div>${name}</div><div style='margin-top: 5px;'>${street}</div>`;
}
function phoneIconFormatter(cell: { getValue: () => any }) {
  const phoneNumber = cell.getValue();

  const phoneContainer = document.createElement('div');
  phoneContainer.className = 'phone-container';
  phoneContainer.style.display = 'flex';
  phoneContainer.style.alignItems = 'center';
  phoneContainer.style.justifyContent = 'center';
  phoneContainer.style.height = '100%';
  phoneContainer.style.width = '100%';

  if (phoneNumber && phoneNumber.trim() !== '') {
    const icon = document.createElement('span');
    icon.className = 'phone-icon';
    icon.className = 'fa-solid fa-phone'; // phone icon
    icon.style.cursor = 'pointer';
    icon.style.textAlign = 'center';
    icon.style.display = 'block';
    icon.title = 'Click to see phone number';

    icon.addEventListener('click', function () {
      document
        .querySelectorAll('.call-interface')
        .forEach(element => element.remove());

      const modal = document.createElement('div');
      modal.className = 'call-interface';
      modal.style.position = 'fixed';
      modal.style.top = '50%';
      modal.style.left = '50%';
      modal.style.transform = 'translate(-50%, -50%)';
      modal.style.border = '1px solid black';
      modal.style.padding = '20px';
      modal.style.backgroundColor = 'white';
      modal.style.zIndex = '1000';

      const span = document.createElement('span');
      span.textContent = phoneNumber;

      const buttonContainer = document.createElement('div');
      buttonContainer.style.display = 'flex';
      buttonContainer.style.justifyContent = 'space-evenly';

      const cancelButton = document.createElement('button');
      cancelButton.textContent = 'Cancel';
      cancelButton.onclick = function () {
        modal.remove();
      };

      const callButton = document.createElement('button');
      callButton.textContent = 'Call';
      callButton.onclick = function () {
        window.location.href = 'tel:' + phoneNumber;
      };

      buttonContainer.appendChild(cancelButton);
      buttonContainer.appendChild(callButton);

      modal.appendChild(span);
      modal.appendChild(buttonContainer);

      document.body.appendChild(modal);
    });

    phoneContainer.appendChild(icon);
  } else {
    phoneContainer.innerHTML = '';
  }
  return phoneContainer;
}
function addToggleBtn() {
  if (document.querySelector('.toggle-container')) {
    return;
  }
  var toggleContainer = document.createElement('div');
  toggleContainer.className = 'toggle-container';
  toggleContainer.style.display = 'flex';
  toggleContainer.style.alignItems = 'center';
  toggleContainer.style.justifyContent = 'flex-start';
  toggleContainer.style.position = 'relative';

  var toggleSwitch = document.createElement('input');
  toggleSwitch.type = 'checkbox';
  toggleSwitch.className = 'toggle-switch';
  toggleSwitch.id = 'toggleSwitch';
  toggleSwitch.checked = true; // Default toggled on
  toggleSwitch.style.cursor = 'pointer';

  var toggleLabel = document.createElement('label');
  toggleLabel.className = 'switch';
  toggleLabel.appendChild(toggleSwitch);

  var slider = document.createElement('span');
  slider.className = 'slider round';
  toggleLabel.appendChild(slider);

  var labelText = document.createElement('span');
  labelText.textContent = 'Websites only';
  labelText.style.position = 'relative';

  // Information icon
  var infoIcon = document.createElement('i');
  infoIcon = document.createElement('span');
  infoIcon.className = 'fas fa-info-circle';
  infoIcon.style.position = 'relative';
  infoIcon.style.top = '-6px';
  infoIcon.style.cursor = 'pointer';

  toggleContainer.appendChild(toggleLabel);
  toggleContainer.appendChild(labelText);
  labelText.appendChild(infoIcon);

  var headerElement = document.querySelector('h1');
  var headerContainer = document.createElement('div');
  headerContainer.className = 'header-container';
  headerElement.parentNode.insertBefore(headerContainer, headerElement);
  headerContainer.appendChild(headerElement);

  headerContainer.appendChild(toggleContainer);
}
function createInfoModal() {
  const modal = document.createElement('div');
  modal.id = 'infoModal';
  modal.className = 'modal';

  const modalContent = document.createElement('div');
  modalContent.className = 'modal-content';
  modalContent.innerHTML = `
      <div class="modal-header">
          <span class="close">&times;
      </div>
      <div class="modal-body">
          <p>Inquirita verifies Websites for valid content. If we cannot find a Website for a listing we do not attempt to validate the listing. "Hide listings without Websites" ensures you only view listings where we have examined and verified the phone number and address and the business appears to be operating.</nobr></p>
      </div>
  `;
  modal.appendChild(modalContent);

  document.body.appendChild(modal);

  const closeButton = modal.querySelector('.close');

  closeButton.onclick = function () {
    modal.style.display = 'none';
  };

  window.onclick = function (event) {
    if (event.target == modal) {
      modal.style.display = 'none';
    }
  };

  return modal;
}
function addDynamicTableId() {
  var staticTable = document.getElementById('static-table');
  if (staticTable) {
    var newDiv = document.createElement('div');
    newDiv.id = 'dynamic-table';
    staticTable.parentNode.insertBefore(newDiv, staticTable.nextSibling);
  }
}
function showDynamicTable() {
  document.getElementById('static-table').style.display = 'none';
  document.getElementById('dynamic-table').style.display = 'block';
}
function setupInteractions() {
  const toggleSwitch = document.getElementById('toggleSwitch');
  const infoIcon = document.querySelector('.fa-info-circle'); // Make sure this selector matches your icon

  if (toggleSwitch) {
    toggleSwitch.addEventListener('change', () => {
      if (toggleSwitch.checked) {
        tabulator.value.setFilter('Name', 'like', '<a href=');
      } else {
        tabulator.value.clearFilter();
      }
    });

    // Set initial state based on toggle switch
    if (toggleSwitch.checked) {
      tabulator.value.setFilter('Name', 'like', '<a href=');
    }
  }

  if (infoIcon) {
    infoIcon.addEventListener('click', () => {
      let modal = document.getElementById('infoModal');
      if (!modal) {
        modal = createInfoModal();
      }
      modal.style.display = 'block';
    });
  }
}
const tableColumns = ref(columns);
const tabulator = ref(null);

onMounted(() => {
  window.addEventListener('resize', function () {
    // postal and street hidden when screen size bigger than 550px
    if (window.innerWidth <= 600) {
      tabulator.value.hideColumn('Postal');
      tabulator.value.hideColumn('Street');
      tabulator.value.hideColumn('Name');
      tabulator.value.showColumn('Combined');
    } else {
      tabulator.value.showColumn('Postal');
      tabulator.value.showColumn('Street');
      tabulator.value.showColumn('Name');
      tabulator.value.hideColumn('Combined');
    }
  });

  const tData = readStaticTableData();
  if (!tData || tData.length === 0) {
    console.log('Static table ID not found');
    return;
  }
  addDynamicTableId();
  addToggleBtn();

  tabulator.value = new Tabulator('#dynamic-table', {
    virtualDomBuffer: 300, // Increase buffer size to control jumping scroll
    data: tData,
    reactiveData: true,
    columns: tableColumns.value,
    layout: 'fitColumns',
    movableColumns: false,
  });

  tabulator.value.on('tableBuilt', () => {
    setupInteractions();
    showDynamicTable();
  });
});
</script>

<template>
  <div ref="table"></div>
</template>
